
  import { Component, Vue } from 'vue-property-decorator'

  import { QuizReviewDataInterface, QuizReviewInterface, QuizInterface, UserAnswerInterface } from '@/interface/BaseInterface'
  import getApi from '@/api'
  import QuizReview from '../../api/quizReview'
  import { size, findIndex, filter } from 'lodash'
  import QuizApi from '../../api/quizApi'
  import QuizAttempt from '../../api/quizAttempt'
  import { namespace } from 'vuex-class'
  import QuizTestResult from '../../components/quizPreview/QuizTestResult.vue'

  const quizReview: QuizReview = getApi('quizReview')
  const quizApi: QuizApi = getApi('quiz')
  const quizAttempt: QuizAttempt = getApi('quizAttempt')

  const tQuizResult = namespace('tQuizResult')

  @Component({ components: { QuizTestResult } })
  export default class QuizReviewPage extends Vue {
    @tQuizResult.Action('setAttemptResult') setAttemptResult!: (data: UserAnswerInterface[]) => void

    data: QuizReviewDataInterface[] = []

    isLoading = false

    activeName = '0'

    attemptSelectedId = ''

    reviewId = ''

    quiz: QuizInterface = {
      _id: '',
      name: '',
      courseId: this.courseId,
      sectionId: '',
      parts: []
    }

    isReload = true
    isSubmit = false

    async init() {
      try {
        this.isLoading = true
        this.data = await quizReview.getAssignAttempt(this.courseId)
        if (size(this.data) > 0) {
          if (size(this.data[0].attempts) > 0) {
            this.attemptSelectedId = this.data[0].attempts[0].attemptId
            this.handleAttemptClick(this.data[0].attempts[0])
          }
        }
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
      }
    }

    async handleAttemptClick(value: QuizReviewInterface) {
      try {
        this.isReload = true
        this.attemptSelectedId = value.attemptId
        this.reviewId = value._id
        if (this.quiz._id !== value.quizId) {
          this.quiz = await quizApi.getById(value.quizId)
        }
        this.setAttemptResult(await quizAttempt.attemptDetailByUser(this.quiz._id, value.attemptId, value.studentId))
        this.isReload = false
      } catch (error) {
        console.log(error)
      }
    }

    reset() {
      this.attemptSelectedId = ''
      this.reviewId = ''
      this.quiz = {
        _id: '',
        name: '',
        courseId: this.courseId,
        sectionId: '',
        parts: []
      }
    }

    async handleSubmit() {
      try {
        this.isLoading = true
        await quizReview.submit(this.courseId, this.reviewId)

        const quizIndex = findIndex(this.data, value => {
          return value.quiz._id === this.quiz._id
        })

        if (quizIndex !== -1) {
          this.data[quizIndex].attempts = filter(this.data[quizIndex].attempts, value => {
            return value.attemptId !== this.attemptSelectedId
          })
          if (size(this.data[quizIndex].attempts) === 0) {
            this.data = filter(this.data, (value, key) => {
              return key !== quizIndex
            })
            this.reset()
            if (size(this.data) > 0) {
              if (size(this.data[0].attempts) > 0) {
                this.attemptSelectedId = this.data[0].attempts[0].attemptId
                this.handleAttemptClick(this.data[0].attempts[0])
              }
            }
          }
        }
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
      }
    }

    created() {
      this.init()
    }
  }
